import React from 'react';
import './field.css';

const Field = ({ className, children, onDragOverEvent, onDropEvent }) => {
  return (
    <div className={`Field ${className}`} onDrop={onDropEvent} onDragOver={onDragOverEvent} >
      {children}
    </div>
  );
};

export default Field;
