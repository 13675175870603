import './piece.css'

const Piece = ({ type, isWhite, handleDrag}) => {
  const c = isWhite ? 'w' : 'b';
  const src = `chess_pieces/${type}${c}.svg`;

  return (
    <div className="Piece" draggable onDragStart={handleDrag}>
      <img alt="piece" src={src} />
    </div>
  );
};

export default Piece;
